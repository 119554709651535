:root {
	font-family: 'Noto Sans', sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #27272a;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-spin-fast {
	animation: spin 0.7s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.depth-md {
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0.05);
}

.sq-image {
	width: 50vw;
	height: 50vw;   
}